// Данный файл - лишь собрание подключений готовых компонентов.
// Рекомендуется создавать отдельный файл в папке components и подключать все там

// Определение операционной системы на мобильных
// import { mobileCheck } from "./functions/mobile-check";
// console.log(mobileCheck())

// Определение ширины экрана
// import { isMobile, isTablet, isDesktop } from './functions/check-viewport';
// if (isDesktop()) {
//   console.log('...')
// }

// Троттлинг функции (для ресайза, ввода в инпут, скролла и т.д.)
// import { throttle } from './functions/throttle';
// let yourFunc = () => { console.log('throttle') };
// let func = throttle(yourFunc);
// window.addEventListener('resize', func);

// Фикс фулскрин-блоков
// import './functions/fix-fullheight';

// Реализация бургер-меню
// import { burger } from './functions/burger';

// Реализация остановки скролла (не забудьте вызвать функцию)
// import { disableScroll } from './functions/disable-scroll';

// Реализация включения скролла (не забудьте вызвать функцию)
// import { enableScroll } from './functions/enable-scroll';

// Реализация модального окна
// import GraphModal from 'graph-modal';
// const modal = new GraphModal();

// Реализация табов
// import GraphTabs from 'graph-tabs';
// const tabs = new GraphTabs('tab');
// console.log(tabs)

// Получение высоты шапки сайта (не забудьте вызвать функцию)
// import { getHeaderHeight } from './functions/header-height';

// Подключение плагина кастом-скролла
// import 'simplebar';

// Подключение плагина для позиционирования тултипов
// import { createPopper, right} from '@popperjs/core';
// createPopper(el, tooltip, {
//   placement: 'right'
// });

// Подключение свайпера
// import Swiper, { Navigation, Pagination } from 'swiper';
// Swiper.use([Navigation, Pagination]);
// const swiper = new Swiper(el, {
//   slidesPerView: 'auto',
// });

// Подключение анимаций по скроллу
// import AOS from 'aos';
// AOS.init();

// Подключение параллакса блоков при скролле
// import Rellax from 'rellax';
// const rellax = new Rellax('.rellax');

// Подключение плавной прокрутки к якорям
// import SmoothScroll from 'smooth-scroll';
// const scroll = new SmoothScroll('a[href*="#"]');

// Подключение событий свайпа на мобильных
// import 'swiped-events';
// document.addEventListener('swiped', function(e) {
//   console.log(e.target);
//   console.log(e.detail);
//   console.log(e.detail.dir);
// });

// import { validateForms } from './functions/validate-forms';
// const rules = [{
//     ruleSelector: '.input-name',
//     rules: [{
//         rule: 'minLength',
//         value: 3,
//         errorMessage: 'Не менее 3 символов',
//       },
//       {
//         rule: 'required',
//         value: true,
//         errorMessage: 'Введите имя'
//       }
//     ]
//   },
//   {
//     ruleSelector: '.input-tel',
//     tel: true,
//     telError: 'Телефон некорректный',
//     rules: [{
//       rule: 'required',
//       value: true,
//       errorMessage: 'Введите телефон'
//     }]
//   },
//   {
//     ruleSelector: '.input-email',
//     rules: [{
//         rule: 'email',
//         errorMessage: 'Некорректный email',
//       },
//       {
//         rule: 'required',
//         // value: true,
//         errorMessage: 'Введите email'
//       }
//     ]
//   },
// ];

// const afterForm = () => {
// console.log('Произошла отправка, тут можно писать любые действия');
// };


///////////// P O P U P /////////////////////////////////////////////////////

// const modals = document ? .querySelectorAll('.modal')
// modals.forEach(el => el.addEventListener('click', handleModalClicked, false))

// function handleModalClicked() {
//   dialog.showModal()
//   validateForms('.form-modal', rules_modal, afterForm)
// }

// const close_btn = document ? .querySelector(".close-btn")
// const dialog = document ? .querySelector("dialog")

// dialog ? .addEventListener("click", e => {
//   const dialogDimensions = dialog.getBoundingClientRect()
//   if (
//     e.clientX < dialogDimensions.left ||
//     e.clientX > dialogDimensions.right ||
//     e.clientY < dialogDimensions.top ||
//     e.clientY > dialogDimensions.bottom
//   ) {
//     dialog.close()
//   }
// })
// close_btn ? .addEventListener('click', () => {
//   dialog.close()
// })

// if (!dialog ? .hasAttribute('open')) {
//   validateForms('.form-page', rules_page, afterForm)
// }