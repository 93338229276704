import './_functions';

//////// L I G H T  G A L L E R Y  ////////////////////////////////

import lightGallery from 'lightgallery';
import lgAutoplay from 'lightgallery/plugins/autoplay'

const lightgallery = document.getElementById('lightgallery')
if (lightgallery) {
  const imgs = lightgallery.querySelectorAll('a')
  const txt = document.createTextNode(`+${imgs.length}`)
  const num = document.createElement('span')
  num.className = 'num'
  num.appendChild(txt)

  let show_more = lightgallery.querySelector('.show-more')
  show_more.appendChild(num)

  lightGallery(document.getElementById('lightgallery'), {
    plugins: [lgAutoplay],
    licenseKey: 'your_license_key',
    speed: 500,
    download: false,
  });
}

//---------------------------------------------------------//

const lightgallery_2 = document.getElementById('lightgallery-2')
if (lightgallery_2) {
  const imgs = lightgallery_2.querySelectorAll('a')
  const txt = document.createTextNode(`+${imgs.length}`)
  const num = document.createElement('span')
  num.className = 'num'
  num.appendChild(txt)

  let show_more = lightgallery_2.querySelector('.show-more')
  show_more.appendChild(num)

  lightGallery(document.getElementById('lightgallery-2'), {
    plugins: [lgAutoplay],
    licenseKey: 'your_license_key',
    speed: 500,
    download: false,
  });
}


//////// L I G H T B O X //////////////////////////////////////////
// import SimpleLightbox from "simplelightbox";
// new SimpleLightbox('.gallery a', {
//   overlayOpacity: .7,
//   //   spinner: false,
//   //   nav: false,
//   //   close: false,
//   //   showCounter: false,
//   //   heightRatio: 1,
//   //   widthRatio: 1
// });

//////// A C C O R D I O N S ///////////////////////////////////////
import Accordion from 'accordion-js'
const accordion_container = document.querySelector('.accordion-container')

if (accordion_container) {
  new Accordion('.accordion-container', {
    openOnInit: [0],
  })
}

/////////////// T A B S ////////////////////////////////////////////
// import GraphTabs from 'graph-tabs';
// const data_tabs = document.querySelector('[data-tabs]')
// if (data_tabs) {
//   const tabs = new GraphTabs('tab');
// }

/////////////// B U R G E R + M E N U - T O P ////////////////////////////////////////
const burger = document.querySelector('.burger')
if (burger) {
  const header = document.querySelector('.header-site')
  const switch_lang = document.querySelector('.switch-lang')
  const menu = document.querySelector('.menu-top-wrap')
  const menu_items_all = document.querySelectorAll('.menu-top .menu-item')
  const menu_items = Array.from(menu_items_all).filter(el => el.querySelector('.menu-sub'))
  const btns = burger.querySelectorAll('img')

  burger.addEventListener('click', onBurger)

  function onBurger(e) {
    // переключатель кнопок бургера
    if (e.target.tagName == 'IMG') {
      btns.forEach(btn => {
        btn.classList.toggle('hidden')
      })
    }
    // показываем / скрываем меню на планшетах и мобильных
    if (!btns[1].classList.contains('hidden')) {
      header.classList.add('menu-show')
      if (window.innerWidth < 400) {
        switch_lang.classList.add('show')
      }
    } else {
      header.classList.remove('menu-show')
      if (window.innerWidth < 400) {
        switch_lang.classList.remove('show')
      }
    }
    // показываем меню
    // добаляем декоративную разделительную линию внизу меню
    if (!btns[1].classList.contains('hidden')) {
      if (window.innerWidth < 1024 && window.innerWidth > 800) {
        menu_items.forEach(el => el.onmouseover = () => { menu.querySelector('.menu-top').classList.add('btm-line') })
        menu_items.forEach(el => el.onmouseout = () => { menu.querySelector('.menu-top').classList.remove('btm-line') })
      }
      menu.style.display = 'grid'
    } else {
      if (window.innerWidth < 1024 || window.innerWidth > 800) {
        menu_items.forEach(el => el.onmouseover = null)
        menu_items.forEach(el => el.onmouseout = null)
      }
      menu.style.display = 'none'
    }
  }

  function initTopMenu() {
    menu.removeAttribute('style')
    header.classList.remove('menu-show')
    switch_lang.classList.remove('show')
    menu.querySelector('.menu-top').classList.remove('btm-line')
    menu_items.forEach(el => {
      if (el.onmouseover) { el.onmouseover = null }
      if (el.onmouseout) { el.onmouseout = null }
    })
    if (btns[0].classList.contains('hidden')) {
      btns.forEach(btn => {
        btn.classList.toggle('hidden')
      })
    }
  }

  window.addEventListener('resize', onResizeMenu)

  function onResizeMenu(e) {
    // location.reload()
    if (window.innerWidth > 1023) {
      initTopMenu()
    }
    if (window.innerWidth < 800) {
      initTopMenu()
    }
  }
}

// //////////// S L I D E R S ///////////////////////////////////////

import Splide from '@splidejs/splide';

//--------------  S L I D E R  D R U G  ---------------------------//

var check_slider_drug = document.querySelector(".slider-drug");
if (check_slider_drug) {
  // console.log(`slider-drug is present`);

  var slider_drug = new Splide('.slider-drug', {
    arrows: false,
    pagination: false,
  });
  var thumbnails = document.getElementsByClassName('thumbnail');
  var current;

  for (var i = 0; i < thumbnails.length; i++) {
    initThumbnail(thumbnails[i], i);
  }

  function initThumbnail(thumbnail, index) {
    thumbnail.addEventListener('click', function() {
      slider_drug.go(index);
    });
  }
  slider_drug.on('mounted move', function() {
    var thumbnail = thumbnails[slider_drug.index];

    if (thumbnail) {
      if (current) {
        current.classList.remove('is-active');
      }

      thumbnail.classList.add('is-active');
      current = thumbnail;
    }
  });
  slider_drug.mount();

} else {
  // console.log(`slider-drug is NOT present`);
}

//--------------  S L I D E R  N E W S  ---------------------------//

var check_slider_news = document.querySelector(".slider-news");
if (check_slider_news) {
  // console.log(`slider-news is present`);

  var slider_news = new Splide('.slider-news', {
    type: 'loop',
    height: 409,
    autoplay: true,
    interval: 3000,
    arrows: true,
    pagination: false,
    breakpoints: {
      767: {
        perPage: 2,
        gap: 20,
      },
      599: {
        perPage: 1,
      },
    }
  });
  slider_news.mount();

} else {
  // console.log(`slider-news is NOT present`);
}

//-----------------slider-blockquote-1-------------------------------//

var check_slider_blockquote_1 = document.querySelector(".slider-blockquote-1");
if (check_slider_blockquote_1) {
  // console.log(`slider-blockquote-1 is present`);

  var check_slider_blockquote_1 = new Splide('.slider-blockquote-1', {
    type: 'loop',
    autoplay: true,
    interval: 3000,
    arrows: true,
    pagination: false,
  });
  check_slider_blockquote_1.mount();

} else {
  // console.log(`slider-blockquote-1 is NOT present`);
}
//-----------------slider-blockquote-2-------------------------------//

var check_slider_blockquote_2 = document.querySelector(".slider-blockquote-2");
if (check_slider_blockquote_2) {
  // console.log(`slider-blockquote-2 is present`);

  var check_slider_blockquote_2 = new Splide('.slider-blockquote-2', {
    type: 'loop',
    autoplay: true,
    interval: 4000,
    arrows: true,
    pagination: false,
  });
  check_slider_blockquote_2.mount();

} else {
  // console.log(`slider-blockquote-2 is NOT present`);
}
//-----------------slider-blockquote-3-------------------------------//

var check_slider_blockquote_3 = document.querySelector(".slider-blockquote-3");
if (check_slider_blockquote_3) {
  // console.log(`slider-blockquote-3 is present`);

  var check_slider_blockquote_3 = new Splide('.slider-blockquote-3', {
    type: 'loop',
    autoplay: true,
    interval: 2500,
    arrows: true,
    pagination: false,
  });
  check_slider_blockquote_3.mount();

} else {
  // console.log(`slider-blockquote-3 is NOT present`);
}
//----------------- SLIDER EVENTS SEARCH -------------------------------//

var check_events_search = document.querySelector(".slider-events-search");
if (check_events_search) {
  // console.log(`slider-events-search is present`);

  var slider_events_search = new Splide('.slider-events-search', {
    type: 'loop',
    autoplay: true,
    interval: 4000,
    arrows: true,
    pagination: false,
  });
  slider_events_search.mount();

} else {
  // console.log(`slider-events-search is NOT present`);
}

/////////////// M O D A L S /////////////////////////////////////////////////////
const html = document.querySelector('html')
const modals = document.querySelectorAll('.modal');
if (modals) {
  const dialogs = document.querySelectorAll("dialog");
  const close_btns = document.querySelectorAll(".btn-close");
  modals.forEach(el => {
    el.addEventListener('click', (e) => {
      if (e.currentTarget.classList.contains('reaction')) {
        e.preventDefault()
        html.classList.add('fixed')
        dialogs.forEach(el => {
          if (el.classList.contains('reaction')) {
            el.showModal()
          }
        })
      }
      if (e.currentTarget.classList.contains('partnerships')) {
        html.classList.add('fixed')
        dialogs.forEach(el => {
          if (el.classList.contains('partnerships')) {
            el.showModal()
          }
        })
      }
    })
  });
  dialogs.forEach(el => {
    el.onclick = (e) => {
      const dialogDimensions = el.getBoundingClientRect();
      if (e.clientX < dialogDimensions.left || e.clientX > dialogDimensions.right || e.clientY < dialogDimensions.top || e.clientY > dialogDimensions.bottom) {
        html.classList.remove('fixed')
        el.close();
      }
    }
  })
  close_btns.forEach(el => {
    el.addEventListener('click', () => {
      html.classList.remove('fixed')
      el.closest('dialog').close()
    })
  })
}
//---------- C O N F I R M A T I O N   M O D A L  --------------------------------//
if (document.querySelector('.page-drugs') || document.querySelector('.page-drug')) {
  let confirmation = document.querySelector('.confirmation')
  const yes = document.querySelector('.yes')
  yes.addEventListener('click', () => {
    html.classList.remove('fixed')
    confirmation.close()
  })
  confirmation.showModal()
  html.classList.add('fixed')
  confirmation.onclick = ''
}

/////////////// L A N G  S W I T C H E R /////////////////////////////////////////////////////
const switch_lang = document.querySelector('.switch-lang')
if (switch_lang) {
  const body = document.querySelector('body')
  initSwitchLang()

  const switch_items = switch_lang.querySelectorAll('.switch-item')
  switch_lang.addEventListener('click', onSwitchLang)

  function onSwitchLang(e) {
    if (e.target.classList.contains('switch-item')) {
      if (!e.target.classList.contains('active')) {
        switch_items.forEach(el => {
          el.classList.toggle('active')
        })
      }
    }
    // change body's class lang_ru/lang_en
    if (switch_lang.querySelector('.switch-item').classList.contains('active')) {
      body.classList.remove('lang_en')
      body.classList.add('lang_ru')
    } else {
      body.classList.remove('lang_ru')
      body.classList.add('lang_en')
    }
  }

  function initSwitchLang() {
    switch_lang.querySelector('.switch-item').classList.add('active')
    if (switch_lang.querySelector('.switch-item').classList.contains('ru')) {
      body.classList.add('lang_ru')
    } else {
      body.classList.add('lang_en')
    }
  }
}
/////////////// D R U G   S E A R C H  //////////////////////////////
let page_drugs = document.querySelector('.page-drugs')
if (page_drugs) {
  let drugs = document.querySelectorAll('.card-drug-block .card-drug')
  let input = document.querySelector('.search')
  input.addEventListener('input', onSearch)

  function onSearch(e) {
    let val = e.currentTarget.value.toLowerCase()
    if (val != '') {
      hideAllDrugs()
      drugs.forEach(drug => {
        let name = drug.querySelector('.name').innerText.toLowerCase()
        if (name.indexOf(val) != -1) {
          drug.classList.remove('hidden')
        }
      })
    }
    if (val === '') { showAllDrugs() }
  }

  function hideAllDrugs() {
    drugs.forEach(drug => drug.classList.add('hidden'))
  }

  function showAllDrugs() {
    drugs.forEach(drug => {
      if (drug.classList.contains('hidden')) {
        drug.classList.remove('hidden')
      }
    })
  }
}

////////////// next-events BLOCK /////////////////////////////////
const next_events = document.querySelector('.next-events')
if (next_events) {
  let w = 840
  const events = next_events.querySelectorAll('.event')

  if (window.innerWidth <= w) {
    wrapEvents(next_events)
  }

  window.addEventListener('resize', onResizeNextEvents)

  function onResizeNextEvents() {
    if (window.innerWidth > w) {
      if (next_events.querySelector('.wrapper')) {
        unwrapEvents(next_events.querySelector('.wrapper'))
      }

    }
    if (window.innerWidth <= w) {
      if (!next_events.querySelector('.wrapper')) {
        wrapEvents(next_events)
      }
    }
  }

  function wrapEvents(parent, className = 'wrapper') {
    const wrapper = document.createElement('div');
    wrapper.classList = className;
    events.forEach(event => { wrapper.appendChild(event) })
    parent.querySelector('.desc').after(wrapper)
  }

  function unwrapEvents(parent) {
    parent.replaceWith(...parent.children)
  }
}
/////////////  F O R M  Z A P R O S  B L O C K   ///////////////////
const zapros_block = document.querySelector('.zapros-block')
if (zapros_block) {
  let cur_text
  const input = zapros_block.nextElementSibling
  const zapros_active = zapros_block.querySelector('.zapros-active')
  const def = zapros_block.querySelector('.def')
  const cur = zapros_block.querySelector('.cur')
  const zapros_items = zapros_block.querySelectorAll('.zapros-item')
  zapros_items.forEach(el => {
    el.addEventListener('click', (e) => {
      hideZaprosItem(e)
      hideDef()
      fillCur(e)
      fillInputValue()
      toggleList()
      highlightZaprosActive()
    })
  })

  zapros_block.querySelector('p').addEventListener('click', () => {
    clearInputValue()
    toggleList()
    initZaprosBlock()
    highlightZaprosActive()
  })

  function toggleList() {
    zapros_block.querySelector('.zapros-list-wrap').classList.toggle('show')
    zapros_block.querySelector('svg').classList.toggle('rotate')
  }

  function hideZaprosItem(e) {
    e.currentTarget.classList.add('hidden')
    cur_text = e.currentTarget.innerText
  }

  function initZaprosBlock() {
    zapros_items.forEach(el => el.classList.remove('hidden'))
    def.classList.remove('hidden')
    cur.innerText = ''
  }

  function hideDef() {
    def.classList.add('hidden')
  }

  function fillCur(e) {
    cur.innerText = cur_text
  }

  function highlightZaprosActive() {
    if (def.classList.contains('hidden')) {
      zapros_active.classList.add('hl')
      zapros_block.classList.add('change-clr')
    } else {
      zapros_active.classList.remove('hl')
      zapros_block.classList.remove('change-clr')
    }
  }

  function fillInputValue() {
    input.value = cur_text
  }

  function clearInputValue() {
    input.value = ''
  }
}

/////////////  E V E N T S  C A L E N D A R  /////////////////////
const page_events_calendar = document.querySelector('.page-events-calendar')
if (page_events_calendar) {
  const arrEvents = [
    { year: 2023, month: 4, day: 29, title: 'Биологическая стоматология', town: 'Липецк', link: 'event-single.html' },
    { year: 2023, month: 4, day: 23, title: 'Стоматология завтрашнего дня', town: 'Воронеж', link: 'event-single.html' },
    { year: 2023, month: 4, day: 3, title: 'Стоматология будущего', town: 'Тула', link: 'event-single.html' },
    { year: 2023, month: 5, day: 16, title: 'Проблемы стоматологии', town: 'Смоленск', link: 'event-single.html' },
    { year: 2023, month: 5, day: 6, title: 'Стоматология успеха', town: 'Москва', link: 'event-single.html' },
    { year: 2023, month: 6, day: 16, title: 'Стоматология в вашем городе', town: 'Санкт-Петербург', link: 'event-single.html' },
    { year: 2023, month: 6, day: 15, title: 'Стоматология вчера, сегодня, завтра', town: 'Норильск', link: 'event-single.html' },
    { year: 2023, month: 6, day: 1, title: 'Стоматология для людей', town: 'Орел', link: 'event-single.html' },
    { year: 2023, month: 7, day: 11, title: 'Новости стоматологии', town: 'Белгород', link: 'event-single.html' },
    { year: 2023, month: 7, day: 20, title: 'Стоматология завтра', town: 'Краснодар', link: 'event-single.html' },
    { year: 2023, month: 7, day: 22, title: 'Успешная стоматология', town: 'Ростов', link: 'event-single.html' },
    { year: 2023, month: 8, day: 5, title: 'Современная ортодонтия', town: 'Ростов-на-Дону', link: 'event-single.html' },
    { year: 2023, month: 8, day: 17, title: 'Биологическая стоматология', town: 'Вологда', link: 'event-single.html' },
    { year: 2023, month: 9, day: 4, title: 'Стоматология завтрашнего дня', town: 'Тольятти', link: 'event-single.html' },
    { year: 2023, month: 9, day: 14, title: 'Проблемы стоматологии', town: 'Смоленск', link: 'event-single.html' },
    { year: 2023, month: 9, day: 21, title: 'Новости стоматологии', town: 'Калининград', link: 'event-single.html' },
    { year: 2023, month: 10, day: 11, title: 'Стоматология будущего', town: 'Рязань', link: 'event-single.html' },
  ]
  const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
  const weekDays = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']

  const initDay = new Date()
  let month = initDay.getMonth()
  let year = initDay.getFullYear()
  let daysInCurrentMonth
  let daysInPrevMonth
  let weekDay

  const table_event_calendar = document.querySelector('.table-event-calendar')
  const dates = table_event_calendar.querySelectorAll('.date')
  const contents = table_event_calendar.querySelectorAll('.content')

  const btn_prev = page_events_calendar.querySelector('.btn-event.prev')
  const btn_next = page_events_calendar.querySelector('.btn-event.next')
  const btn_now = page_events_calendar.querySelector('.btn-now')
  const cal_month = page_events_calendar.querySelectorAll('.cal-month')
  const cal_year = page_events_calendar.querySelectorAll('.cal-year')
  let events_list = page_events_calendar.querySelector('.events-list')
  btn_prev.addEventListener('click', onBtnPrev)
  btn_next.addEventListener('click', onBtnNext)
  btn_now.addEventListener('click', onBtnNow)

  initCalendar()

  function initCalendar() {
    cal_month.forEach(el => { el.innerText = showMonth(month) })
    cal_year.forEach(el => { el.innerText = year })

    daysInCurrentMonth = (new Date(year, month + 1, 0).getDate())
    daysInPrevMonth = (new Date(year, month, 0).getDate())
    weekDay = getWeekDay()

    fillCalendar(weekDay)
  }

  function onBtnPrev() {
    if (month == 0) {
      month = 11
      year = year - 1
    } else {
      month--
    }
    initCalendar()
  }

  function onBtnNext() {
    if (month == 11) {
      month = 0
      year = year + 1
    } else {
      month++
    }
    initCalendar()
  }

  function onBtnNow() {
    month = initDay.getMonth()
    year = initDay.getFullYear()
    initCalendar()
  }

  function showMonth(month) {
    let m = months[month]
    return m
  }

  function fillCalendar(weekDay) {
    dates.forEach((el) => {
      el.innerText = ''
      el.classList.remove('bright', 'today')
    })
    contents.forEach((el) => {
      el.innerHTML = ''
    })
    events_list.innerHTML = ''

    let arrCurMonthEvents = arrEvents.filter(el => {
      return (el.year == year && el.month == month + 1)
    })

    for (let i = 0; i < daysInCurrentMonth; i++) {
      dates[i + weekDay - 1].innerText = i + 1
      dates[i + weekDay - 1].classList.add('bright')
    }
    const ss = table_event_calendar.querySelectorAll('.bright')

    if (month == initDay.getMonth() && year == initDay.getFullYear()) {
      ss.forEach(s => {
        if (s.classList.contains('bright') && s.innerText == initDay.getDate()) {
          s.classList.add('today')
        }
      })
    }
    ss.forEach(s => {
      arrCurMonthEvents.forEach(ev => {
        if (ev.day == s.innerText) {
          const a = document.createElement('a')
          const name = document.createElement('span')
          name.classList.add('name')
          name.innerText = ev.title
          const place = document.createElement('span')
          place.classList.add('place')
          place.innerText = ev.town
          a.classList.add('event')
          a.setAttribute('href', `/${ev.link}`)
          a.appendChild(name)
          a.appendChild(place)
          s.nextElementSibling.appendChild(a)

          let li = document.createElement('li')

          let e_date = document.createElement('span')
          e_date.classList.add('e-date')
          e_date.innerText = ev.day

          let e_day = document.createElement('span')
          e_day.classList.add('e-day')
          let wD = new Date(year, month, s.innerText).getDay()
          e_day.innerText = weekDays[wD]

          let e_name = document.createElement('span')
          e_name.classList.add('e-name')
          e_name.innerText = ev.title

          let e_place = document.createElement('span')
          e_place.classList.add('e-place')
          e_place.innerText = ev.town

          let e_content = document.createElement('a')
          e_content.setAttribute('href', `/${ev.link}`)
          e_content.classList.add('e-content')
          e_content.appendChild(e_name)
          e_content.appendChild(e_place)

          li.appendChild(e_date)
          li.appendChild(e_day)
          li.appendChild(e_content)

          events_list.appendChild(li)
        }
      })
    })

    let daysBefore = weekDay - 1
    let daysAfter = 42 - (weekDay + daysInCurrentMonth - 1)
    for (let a = 0; a < daysAfter; a++) { dates[41 - a].innerText = daysAfter - a }
    for (let b = 0; b < daysBefore; b++) { dates[b].innerText = daysInPrevMonth - daysBefore + 1 + b }
  }

  function getWeekDay() {
    let wd = new Date(year, month, 1).getDay()
    if (wd == 0) wd = 7
    return wd
  }
}

/////////////  D R U G  C H O O S I N G  /////////////////////////

const search_block = document.querySelector('.search-block')
if (search_block) {
  const btns_req = search_block.querySelectorAll('.btn-req')
  btns_req.forEach(btn => btn.addEventListener('click', onClickBtn))
  const drug_cards = document.querySelectorAll('.card-drug-block .card-drug')
  const defs = document.querySelectorAll('.search-block .def')
  const curs = document.querySelectorAll('.search-block .cur')
  const level_tops = document.querySelectorAll('.search-block .level-top')
  level_tops.forEach(el => el.addEventListener('click', onClickLevelTop))

  function onClickBtn(e) {
    hideCards()
    showBtnsReq()
    showDefs()
    emptyCurs()
    disactiveLevelTops()
    const request = e.currentTarget.innerText
    drug_cards.forEach(card => {
      const attrs = card.attributes
      for (var k = 0; k < attrs.length; k++) {
        if (attrs[k].value == request) {
          card.classList.remove('hidden')
        }
      }
    })
    e.currentTarget.parentElement.classList.add('hidden')
    const cls = e.currentTarget.closest('.parent')
    const cur = cls.querySelector('.cur')
    cur.innerText = request
    cur.previousElementSibling.classList.add('hidden')
    cls.querySelector('.level-top').classList.add('active')
  }

  function hideCards() {
    drug_cards.forEach(el => el.classList.add('hidden'))
  }

  function showCards() {
    drug_cards.forEach(el => el.classList.remove('hidden'))
  }

  function showBtnsReq() {
    btns_req.forEach(el => el.parentElement.classList.remove('hidden'))
  }

  function showDefs() {
    defs.forEach(el => el.classList.remove('hidden'))
  }

  function emptyCurs() {
    curs.forEach(el => el.innerText = '')
  }

  function disactiveLevelTops() {
    level_tops.forEach(el => el.classList.remove('active'))
  }

  function onClickLevelTop() {
    disactiveLevelTops()
    showBtnsReq()
    showDefs()
    emptyCurs()
    showCards()
  }
}

/////////////  F O R M  A T T A C H  F I L E  /////////////////////////

const vacancy_form_block = document.querySelector('.vacancy-form-block')
if (vacancy_form_block) {
  const input_file = vacancy_form_block.querySelector('.input-file')
  const btn_attach = vacancy_form_block.querySelector('.btn-attach')
  input_file.addEventListener('change', onChange)

  function onChange() {
    if (input_file.value != '') {
      btn_attach.classList.add('attached')
      btn_attach.querySelector('.text').innerText = 'Файл прикреплен'
    }
  }
}